import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { JWTHeaders } from 'src/app/services/collection.service';
import { JwtService } from 'src/app/services/jwt.service';
import { Marketinginfo } from 'src/app/shared/models/marketinginfo.model';
import { environment } from 'src/environments/environment';

const marketingInfoUrl = environment.apiURL + 'marketinginfos/ApiSingleProject/--Id--';
const marketingHeaderInfoUrl = environment.apiURL + 'marketinginfos/ApiSingleProjectHeader/--Id--';
const marketingCollectionGalleryPartUrl = environment.apiURL +
    'marketinginfos/ApiCollectionGalleryPart/--collectionPartId--/--collectionId--';

@Injectable({
    providedIn: 'root',
})
export class MarketingService {

    constructor(
        private http: HttpClient,
        private jwtService: JwtService,
    ) {
    }

    getMarketingInfo(marketingInfoId: string, jwtToken: string) {
        return this.http.get<Marketinginfo>(marketingInfoUrl.replace('--Id--', marketingInfoId), { headers: this.jwtService.createJWTHeader(jwtToken) }).pipe(
            tap(res => res),
        );
    }

    getMarketingInfoFromCollectionPart(collectionpartId: number,
                                       collectionId: string,
                                       jwtToken: string) {
        const getCollectionpartUrl = marketingCollectionGalleryPartUrl.replace('--collectionPartId--', collectionpartId.toString());
        return this.http.get<Marketinginfo>(getCollectionpartUrl.replace('--collectionId--', collectionId), { headers: this.jwtService.createJWTHeader(jwtToken) })
            .pipe(
                tap(res => res),
            );
    }

    getMarketingInfoHeader(marketingInfoId: string, jwtToken: string) {
        return this.http.get<any>(marketingHeaderInfoUrl.replace('--Id--', marketingInfoId), { headers: this.jwtService.createJWTHeader(jwtToken) }).pipe(
            tap(res => res),
        );
    }

    postMarketingInfoPassword(marketingInfoId: string, jwtToken: string, password: string) {
        return this.http.post<any>(marketingHeaderInfoUrl.replace('--Id--', marketingInfoId), {password} , { headers: this.jwtService.createJWTHeader(jwtToken) }).pipe(
            tap(res => res),
        );
    }
}
