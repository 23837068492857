import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JwtService } from 'src/app/services/jwt.service';
import { MarketingService } from 'src/app/services/marketing.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { Marketinginfo } from 'src/app/shared/models/marketinginfo.model';
import { environment } from 'src/environments/environment';

const user = environment.apiUser;
const password = environment.apiPassword;

@Component({
    selector: 'app-marketing-single-view',
    templateUrl: './marketing-single-view.component.html',
    styleUrls: ['./marketing-single-view.component.scss'],
})
export class MarketingSingleViewComponent implements OnInit {
    loading = true;

    ngUnsubscribe = new Subject();

    marketingInfo;

    isLoggedIn = false;

    token = null;

    imageUrl = environment.imagesURL;

    marketingInfoHeader;

    password = false;

    showAskForPassword = false;

    inputPassword: string;

    passwordLoading = false;

    marketingInfoId: string;

    showProjectFiles = true;

    constructor(
        private marketingService: MarketingService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private jwtService: JwtService,
        private modalService: NgbModal,
    ) {
    }

    ngOnInit() {
        this.onGetCredentialsToken();
    }

    onGetMarketinginfo() {
        this.marketingInfoId = this.activatedRoute.snapshot.params.id;

        this.marketingService.getMarketingInfoHeader(this.marketingInfoId, this.token).pipe(
            takeUntil(this.ngUnsubscribe),
        ).subscribe(
            (response) => {
                this.marketingInfoHeader = response.marketinInfo;
                this.password = response.password;

                if (!this.password) {
                    this.marketingInfo = response.mInfo;
                    this.checkIfProjectFilesAreDefined();
                    this.loading = false;
                } else {
                    this.askForPassword();
                    this.loading = false;
                }
            },
            (error) => {
                window.location.href = 'https://www.vervievas.com';
            },
        );
    }

    // get an access token for this session
    onGetCredentialsToken() {
        this.jwtService.login(user, password).pipe(
            takeUntil(this.ngUnsubscribe),
        ).subscribe(
            res => {
                if (res) {

                    this.isLoggedIn = true;
                    const success = res.success;
                    this.token = res.data.token;

                    this.onGetMarketinginfo();
                } else {

                    this.isLoggedIn = false;
                    this.token = null;
                    this.router.navigate(['/error']);
                }
            },
        );
    }

    onOpenModal(projectFile) {

        if (projectFile.s3Link) {
            const modalRef = this.modalService.open(ModalComponent, {
                windowClass: 'videoAssetModal',
                centered: true,
            });
            modalRef.componentInstance.data = {
                videoLink: projectFile.s3Link,
            };
        }
    }

    askForPassword() {
        this.showAskForPassword = true;
    }

    onSubmitPassword() {
        this.passwordLoading = true;
        this.marketingService.postMarketingInfoPassword(this.marketingInfoId, this.token, this.inputPassword)
            .pipe(
                takeUntil(this.ngUnsubscribe),
            ).subscribe(
            (response) => {
                this.password = response.password;
                if (!this.password) {
                    this.inputPassword = '';
                    this.marketingInfo = response.mInfo;
                    this.passwordLoading = false;
                    this.showAskForPassword = false;
                    this.checkIfProjectFilesAreDefined();
                } else {
                    this.inputPassword = '';
                    this.loading = false;
                    this.passwordLoading = false;
                    this.showAskForPassword = true;
                }
            },
            (error) => {

            },
        );
    }

    checkIfProjectFilesAreDefined() {
        if (!this.marketingInfo.projectfiles) {
            this.showProjectFiles = false;
        }
    }

}
