import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-collectionpart-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  @Input() collectionPart = null;
  constructor() { }

  ngOnInit() {
  }

}
