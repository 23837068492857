import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],

})
export class GalleryComponent implements OnInit, AfterViewInit {
    @ViewChild('carousel', null) carousel: NgbCarousel;

    @Input() items = [];

    @Input() project: any;

    @Input() thumbnail: string;

    @Input() imageUrl: string;

    @Input() singleView = false;

    loading = true;

    activeSliderId = 'ngb-slide-0';

    constructor(private config: NgbCarouselConfig,
                private modalService: NgbModal,
        ) {
    }

    ngOnInit() {
        console.log(this.items.length);
    }

    ngAfterViewInit() {
        this.carousel.pause();
    }

    onSlide(event) {
        const imageIndex = parseInt(event.current.replace('ngb-slide-', ''), 10);
    }

    onGoToSlide(goToSlide) {
        if (this.items.length === 0) {
            goToSlide++;
        }
        this.activeSliderId = 'ngb-slide-' + goToSlide;
    }

    fileIsNotVideo(file) {
        return file.mime_type !== 'video/mp4';
    }

    onOpenModal(file) {

        const modalRef = this.modalService.open(ModalComponent, {
            windowClass: 'videoAssetModal',
            centered: true,
        });
        modalRef.componentInstance.data = {
            videoLink: file.s3Link,
        };
    }

    onGetThumbnail(item) {
        if (this.fileIsNotVideo(item)) {
            return this.imageUrl + item.thumbnail;
        } else {
            return '/assets/img/button-play.svg';
        }

    }
}
