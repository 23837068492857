import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, Title,
} from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';
import { NgxGalleryModule } from 'ngx-image-video-gallery';
import { MarketingSingleViewComponent } from 'src/app/marketing/marketing-single-view/marketing-single-view.component';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CollectionsComponent } from './collections/collections.component';
import { AssetMixedComponent } from './collections/components/asset-mixed/asset-mixed.component';
import { AssetVideoComponent } from './collections/components/asset-video/asset-video.component';
import { DividerComponent } from './collections/components/divider/divider.component';
import { TextareaComponent } from './collections/components/textarea/textarea.component';
import { TitleComponent } from './collections/components/title/title.component';
import { GalleryComponent } from './shared/components/gallery/gallery.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { ModalGalleryComponent } from './shared/components/modal-gallery/modal-gallery.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { NotfoundComponent } from './shared/components/notfound/notfound.component';
import { IndexComponent } from './shared/components/pages/index/index.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';

const whitelistedDomains = environment.whiteListedDomains;

export class CustomHammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false },
    };
}

export function jwtTokenGetter() {
    return localStorage.getItem('access_token');
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        IndexComponent,
        CollectionsComponent,
        SpinnerComponent,
        DividerComponent,
        TitleComponent,
        TextareaComponent,
        AssetVideoComponent,
        ModalComponent,
        NotfoundComponent,
        GalleryComponent,
        MarketingSingleViewComponent,
        AssetMixedComponent,
        AssetMixedComponent,
        ModalGalleryComponent,

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        NgxGalleryModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                whitelistedDomains,
                blacklistedRoutes: ['http://localhost:3000/login'],
                throwNoTokenError: false,
            },
        }),
        FormsModule,
    ],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig
        },
        NgbModule,
        NgbActiveModal,
        ModalComponent,
        ModalGalleryComponent,
        Title,
    ],
    bootstrap: [AppComponent],
    exports: [
        GalleryComponent,
    ],
    entryComponents: [
        ModalComponent,
        ModalGalleryComponent,
        AssetVideoComponent,
    ],
})
export class AppModule {
}
