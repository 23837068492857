import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MarketingSingleViewComponent } from 'src/app/marketing/marketing-single-view/marketing-single-view.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { IndexComponent } from './shared/components/pages/index/index.component';
import { CollectionsComponent } from './collections/collections.component';
import { NotfoundComponent } from './shared/components/notfound/notfound.component';

const routes: Routes = [
    {
        path: '404',
        component: NotfoundComponent,
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: IndexComponent,
            }
            ,
            {
                path: 'c',
                component: CollectionsComponent,
            }
            ,
            {
                path: 'c/:id',
                component: CollectionsComponent,
            },
            {
                path: 'm',
                component: MarketingSingleViewComponent,
            },
            {
                path: 'm/:id',
                component: MarketingSingleViewComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules,
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
