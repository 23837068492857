import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Collection} from 'src/app/shared/models/collection.model';
import {environment} from 'src/environments/environment';

const getCollectionURL = environment.apiURL + 'collections/viewPublic/{id}';
const getCollectionHeaderURL = environment.apiURL + 'collections/header/{id}';
const getCollectionPWURL = environment.apiURL + 'collections/viewProtected/{id}';

export const JWTHeaders = new HttpHeaders()
  .set('Accept', 'application/json')
  .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
  .set('Access-Control-Allow-Origin', '*');


interface CollectionResponse {
  collection: Collection;
}

@Injectable({
  providedIn: 'root',
})
export class CollectionsService {

  collection: Collection;

  constructor(private http: HttpClient) {

  }

  public getCollection(id: string, token: string): Observable<any> {
    return this.http.get<CollectionResponse>(getCollectionURL.replace(/{id}/, id), { headers: this.createJWTHeader(token) }).pipe(
      tap(
        res => {
          return res;
        },
      ),
      map((res) => res),
    );
  }

  public getCollectionHeader(id: string, token: string): Observable<any> {
      return this.http.get<CollectionResponse>(getCollectionHeaderURL.replace(/{id}/, id), {headers: this.createJWTHeader(token)}).pipe(
        tap(
          res => res
        , err => {
            return false;
          })
      );
  }

  public createJWTHeader(token) {
    return new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('Access-Control-Allow-Origin', '*');
  }

  public getCollectionPw(id: string, password: string, token: string) {

    return this.http.post<any>(getCollectionPWURL.replace(/{id}/, id), {password}, {headers: this.createJWTHeader(token)}).pipe(
        tap(
          res => {
            return res;
          },
          err => {
            return false;
          }
        )
    );
  }

}
