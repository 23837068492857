import { Component, Injectable, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../../shared/components/modal/modal.component';

@Component({
    selector: 'app-collectionpart-asset-video',
    templateUrl: './asset-video.component.html',
    styleUrls: ['./asset-video.component.scss'],
})
export class AssetVideoComponent implements OnInit {
    @Input() collectionPart = null;

    displayImage = false;

    public imageBaseURL = environment.imagesURL;

    constructor(private modalService: NgbModal) {
    }

    ngOnInit() {
        this.displayImage = true;
    }

    onOpenModal() {
        const videoFile = this.getVideoFileInProjectFiles();
        //console.log(videoFile);
        if (this.collectionPart) {
            const modalRef = this.modalService.open(ModalComponent, {
                windowClass: 'videoAssetModal',
                centered: true,
            });
            modalRef.componentInstance.data = {
                videoLink: videoFile[0].s3Link,
            };
        }
    }

    isEmpty(value) {
        return (value === '' || value == null);
    }

    getVideoFileInProjectFiles(){
        return this.collectionPart.projectfiles.filter((projectFile) => {
            return projectFile.mime_type === 'video/mp4';
        });
    }

}
