import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MarketingService } from 'src/app/services/marketing.service';
import { Collectionpart } from 'src/app/shared/models/collectionpart.model';
import { Marketinginfo } from 'src/app/shared/models/marketinginfo.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-gallery',
    templateUrl: './modal-gallery.component.html',
    styleUrls: ['./modal-gallery.component.scss'],
})
export class ModalGalleryComponent implements OnInit {

    @Input() data: any;

    collectionId: string;

    collectionPart: Collectionpart;

    jwtToken: string;

    public marketingInfo: Marketinginfo;

    loading = true;

    ngUnsubscribe = new Subject();

    showError = false;

    imageURL = environment.imagesURL;


    constructor(private marketingService: MarketingService) {

    }

    ngOnInit() {

        this.collectionId = this.data.collectionPart.collection_id;
        this.collectionPart = this.data.collectionPart;
        this.jwtToken = this.data.jwtToken;

        this.marketingService.getMarketingInfoFromCollectionPart(this.collectionPart.id, this.collectionId, this.jwtToken).pipe(
            takeUntil(this.ngUnsubscribe),
        ).subscribe(
            (response) => {
                this.loading = false;
                this.marketingInfo = response;
            },
            (error) => {
                this.showError = true;
            },
        );
    }

}
