import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const loginUrl = environment.apiURL + 'users/token';

const getJsonResponse = new HttpHeaders()
    .set('Accept', 'application/json');

@Injectable({
    providedIn: 'root',
})
export class JwtService {

    constructor(private http: HttpClient) {
    }

    public login(username: string, password: string) {
        return this.http.post<any>(loginUrl, { username, password }, { headers: getJsonResponse })
            .pipe(
                tap(res => {
                    if (res.success === true) {
                        return res.data.token;
                    } else {
                        return false;
                    }

                }),
            );
    }

    public createJWTHeader(token) {
        return new HttpHeaders()
            .set('Accept', 'application/json')
            .set('Authorization', 'Bearer ' + token)
            .set('Access-Control-Allow-Origin', '*');
    }

    public get logout(): void {
        return localStorage.removeItem('access_token');
    }

    public get isLoggedIn(): boolean {
        return localStorage.getItem('access_token') !== null;
    }
}
