import {Component, OnInit,  OnDestroy} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {CollectionsService} from '../services/collection.service';
import {Subject} from 'rxjs';
import {Collection} from '../shared/models/collection.model';
import {JwtService} from '../services/jwt.service';
import {environment} from '../../environments/environment';

const user = environment.apiUser;
const password = environment.apiPassword;


@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy {

  collectionID = null;
  ngUnsubscribe = new Subject();
  collection: Collection;
  loading = true;
  isLoggedIn = false;
  token: string = null;
  isPasswordSet = false;
  askForPassword = false;
  inputPassword = null;
  showPasswordIncorrect = false;
  passwordLoading = false;

  constructor(private activatedRoute: ActivatedRoute,
              private collectionService: CollectionsService,
              private jwtService: JwtService,
              private router: Router,
              private titleService: Title) {
  }

  ngOnInit() {
    this.collectionID = this.activatedRoute.snapshot.params.id;
    this.onGetCredentialsToken();
    // this.onLoadCollection();
  }

  onLoadCollection() {
    this.collectionService.getCollection(this.collectionID, this.token).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      res => {
        this.collection = res;
        this.loading = false;

        this.setTitle(this.collection.title);
      },
    );
  }

  // get an access token for this session
  onGetCredentialsToken() {
    this.jwtService.login(user, password).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      res => {
        if (res) {

          this.isLoggedIn = true;
          const success = res.success;
          this.token = res.data.token;

          this.onGetCollectionInfo();
        } else {

          this.isLoggedIn = false;
          this.token = null;
          this.router.navigate(['/error']);
        }
      }
    );
  }

  onGetCollectionInfo() {
    this.collectionService.getCollectionHeader(this.collectionID, this.token).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      res => {
        if (res === false) {
          this.router.navigate(['/404']);
        }
        if (res.protected === true) {
          if (!this.isPasswordSet) {
            this.onAskForPassword();
          }
        } else {
          this.onLoadCollection();
        }
      },
      res => {
        this.router.navigate(['/404']);
      }
    );
  }

  onAskForPassword() {
    this.askForPassword = true;
  }

  onSubmitPassword() {
    this.passwordLoading = true;
    this.showPasswordIncorrect = false;
    const result = this.collectionService.getCollectionPw(this.collectionID, this.inputPassword, this.token).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(
      res => {
        this.passwordLoading = false;
        if (!res) {
          this.showPasswordIncorrect = true;
        } else {
          this.askForPassword = false;
          this.loading = true;
          this.collection = res;
          this.loading = false;
        }

      },
      (err) => {
        this.passwordLoading = false;
        this.showPasswordIncorrect = true;
      }
    );
  }

  setTitle(title: string) {
      this.titleService.setTitle(title);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
