import { Component, Injectable, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GalleryComponent } from 'src/app/shared/components/gallery/gallery.component';
import { ModalGalleryComponent } from 'src/app/shared/components/modal-gallery/modal-gallery.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-collectionpart-asset-mixed',
    templateUrl: './asset-mixed.component.html',
    styleUrls: ['./asset-mixed.component.scss'],
})
export class AssetMixedComponent implements OnInit {
    @Input() collectionPart = null;
    @Input() jwtToken: string;

    displayImage = true;

    imageBaseURL = environment.imagesURL;

    constructor(private modalService: NgbModal) {
    }

    ngOnInit() {

    }

    onOpenModal() {
        const modalRef = this.modalService.open(ModalGalleryComponent, {
            windowClass: 'videoAssetModal',
            centered: true,
        });
        modalRef.componentInstance.data = {
            collectionPart: this.collectionPart,
            jwtToken: this.jwtToken
        };
    }

    isEmpty(value) {
        return (value === '' || value == null);
    }

}
